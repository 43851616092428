const hostname = window.location.hostname

export const EPMC_ROOT =
  hostname.includes('europepmc.org') ||
  (hostname.includes('ebi.ac.uk') &&
    !hostname.includes('literature-services') &&
    !hostname.includes('design-internal') &&
    !hostname.includes('lit-aperta-staging-sm')) ||
  hostname.includes('193.62.54.148')
    ? '/'
    : 'https://test.europepmc.org/'

export const EPMC_API_ROOT = EPMC_ROOT + 'api/'

export const EPMC_API_GET_ROOT = EPMC_API_ROOT + 'get/'

export const EPMC_API_FT_REPO_ROOT = EPMC_API_ROOT + 'fulltextRepo?'

export const EPMC_WEB_SERVICES_ROOT =
  'https://www.ebi.ac.uk/europepmc/webservices/rest/'

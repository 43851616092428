<script>
import { MENUS } from '@/config'
import SocialPanel from '@/templates/static/SocialPanel'
import FeedbackModal from '@/templates/FeedbackModal'
import Confirmation from 'epmc-patterns/components/v2/Confirmation'

export default {
  components: {
    SocialPanel,
    FeedbackModal,
    Confirmation,
  },
  data() {
    return {
      MENUS,
      showFeedbackModal: false,
      showFeedbackConfirmation: false,
    }
  },
  methods: {
    isExternalLink(item) {
      return item.link.includes('//')
    },
    onFeedbackModalClosed(e) {
      if (e && e.success) {
        this.showFeedbackConfirmation = true
      }
      this.showFeedbackModal = false
    },
    onFeedbackConfirmationClosed() {
      this.showFeedbackConfirmation = false
    },
  },
}
</script>
<template>
  <div id="footer">
    <social-panel />
    <div id="menus" class="grid-row">
      <div class="col-16">
        <div v-for="menu in MENUS" :key="menu.category">
          <p class="h5">
            <a v-if="isExternalLink(menu)" :href="menu.link">{{
              menu.category
            }}</a>
            <a v-else :href="menu.link" :target="menu | target">{{
              menu.category
            }}</a>
          </p>
          <div v-for="item in menu.list" :key="item.text">
            <a
              v-if="isExternalLink(item)"
              :href="item.link"
              class="extra-small"
              :on-click="item.onclick"
              >{{ item.text }}</a
            >
            <a
              v-else
              :href="item.link"
              class="extra-small"
              :onClick="item.onclick"
              >{{ item.text }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div id="feedback">
      <div class="grid-row">
        <div class="col-16">
          <span id="feedback-label">Let us know how we are doing.</span>
          <span id="mobile-feedback-label">How are we doing.</span>
          <input
            id="feedback-button"
            type="button"
            class="secondary"
            value="Give feedback"
            @click.prevent="showFeedbackModal = true"
          />
        </div>
      </div>
    </div>
    <feedback-modal v-if="showFeedbackModal" @close="onFeedbackModalClosed" />
    <div v-if="showFeedbackConfirmation" id="feedback-confirmation">
      <confirmation @close="onFeedbackConfirmationClosed">
        Thank you for your feedback.
      </confirmation>
    </div>
    <div id="elixir-banner">
      <div class="grid-row">
        <div class="col-16">
          <a href="//www.elixir-europe.org/platforms/data/core-data-resources">
            <div id="elixir-logo-kite"></div>
            <p class="h5">Europe PMC is part of the ELIXIR infrastructure</p>
            <div id="elixir-logo-text" class="small">
              Europe PMC is an ELIXIR Core Data Resource
              <span id="elixir-banner-learn-more">Learn more ></span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div id="footer-bottom">
      <div class="grid-row">
        <div class="col-4 extra-small">
          <a
            href="//globalbiodata.org/scientific-activities/global-core-biodata-resources/"
          >
            <div id="gcbr-logo"></div>
            <div id="gcbr-desc-mob">
              Europe PMC is a GBC global core biodata resource.
            </div>
          </a>
        </div>
        <div id="service-desc" class="col-12 extra-small">
          Europe PMC is a service of the
          <a href="/Funders">Europe PMC Funders' Group</a>, in partnership with
          the <a href="//www.ebi.ac.uk/">European Bioinformatics Institute</a>;
          and in cooperation with the
          <a href="//www.ncbi.nlm.nih.gov/">
            National Center for Biotechnology Information
          </a>
          at the
          <a href="//www.nlm.nih.gov/">
            U.S. National Library of Medicine (NCBI/NLM) </a
          >. It includes content provided to the
          <a href="//www.ncbi.nlm.nih.gov/pmc/">PMC International archive</a>
          by participating publishers.
        </div>
      </div>
      <div class="grid-row">
        <div class="col-4 extra-small">
          <div id="gcbr-desc">
            <a
              href="//globalbiodata.org/scientific-activities/global-core-biodata-resources/"
            >
              Europe PMC is a GBC global core biodata resource.
            </a>
          </div>
        </div>
        <div id="footer-bottom-links" class="col-12 extra-small">
          <a href="/ContactUs">Contact us</a>
          |
          <a href="/PrivacyNotice">Privacy</a>
          |
          <a href="//www.ebi.ac.uk/Information/termsofuse.html">Terms of use</a>
          |
          <a href="/Copyright">Copyright</a>
          |
          <a href="/Accessibility">Accessibility</a>
        </div>
      </div>
      <!--<div class="grid-row">
      </div>-->
    </div>
  </div>
</template>
<style scoped lang="scss">
#footer {
  background-color: lighten($epmc-darker-grey, 60%);
  text-align: left;
  #menus {
    padding-bottom: $base-unit * 6;
    @media screen and (max-width: $breakpoint-extra-small) {
      display: none;
    }
    .col-16 {
      display: flex;
      justify-content: space-between;
    }
    .h5 {
      margin-bottom: $base-unit;
    }
  }
  #feedback {
    background-color: $epmc-darker-blue;
    line-height: $base-unit * 9;
    padding: ($base-unit * 4) 0;
    font-size: $base-unit * 4.5;
    color: $epmc-white;
    #mobile-feedback-label {
      display: none;
    }
    @media screen and (max-width: $breakpoint-extra-small) {
      #feedback-label {
        display: none;
      }
      #mobile-feedback-label {
        display: inline;
      }
    }
    #feedback-button {
      float: right;
    }
  }
  #elixir-banner {
    background-color: rgb(77, 77, 72);
    a {
      color: $epmc-white;
      text-decoration: none;
      &:hover {
        opacity: 0.8;
      }
    }
    #elixir-logo-kite {
      position: relative;
      float: left;
      display: inline-block;
      margin: ($base-unit * 4) ($base-unit * 4) ($base-unit * 3)
        ($base-unit * -2);
      width: $base-unit * 15;
      height: $base-unit * 15;
      background: 80% 58% url(~@/assets/elixir.png) no-repeat;
    }
    .h5 {
      margin-left: $base-unit * 17;
      margin-bottom: 0;
    }
    #elixir-logo-text {
      margin-bottom: $base-unit * 5.5;
      margin-left: $base-unit * 17;
    }
    #elixir-banner-learn-more {
      border-bottom: 1px dotted $epmc-white;
      @media screen and (max-width: $breakpoint-extra-small) {
        display: none;
      }
    }
  }
  #service-desc {
    text-align: center;
  }
  #gcbr-logo {
    position: relative;
    float: left;
    display: inline-block;
    margin-top: ($base-unit * -2);
    width: $base-unit * 31;
    height: $base-unit * 13.5;
    background: 80% 58% url(~@/assets/gcbr-logo.svg) no-repeat;
    div {
    }
  }
  #gcbr-desc {
    width: $base-unit * 31;
    height: $base-unit * 13.5;
    margin-top: $base-unit * 5;

    @media screen and (max-width: $breakpoint-extra-small) {
      display: none;
    }
  }
  #gcbr-desc-mob {
    display: none;
    @media screen and (max-width: $breakpoint-extra-small) {
      display: inline-block;
      width: $base-unit * 35;
      height: $base-unit * 12;
      margin-top: $base-unit * -2;
      margin-left: $base-unit * 8;
      margin-bottom: $base-unit * 6;
    }
  }
  #footer-bottom {
    padding: ($base-unit * 4) 0;
    #footer-bottom-links {
      text-align: center;
      margin-top: $base-unit * 5;
    }
  }
}
</style>

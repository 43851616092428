import VueRouter from 'vue-router'
import routerConfig from './router-config'
import { registerMatomoEvent, registerMatomoPageView } from '@/helpers/matomo'
import { isBeta } from '@/helpers/sessionStorage'
import {
  getBetaSearchPath,
  getProductionSearchPath,
  isValidSearchPath,
} from '@/helpers/search'

const router = new VueRouter(routerConfig)

router.beforeEach((to, from, next) => {
  registerMatomoPageView(to)
  // matomo registration for search button event
  if (
    to &&
    isValidSearchPath(to.name) &&
    to.fullPath &&
    !to.fullPath.includes('&page=') &&
    to.query.query
  ) {
    registerMatomoEvent('Common', 'Search button', to.query.query)
  }
  if (to.params && to.params.matomo_event) {
    registerMatomoEvent(
      to.params.matomo_event.category,
      to.params.matomo_event.action,
      to.params.matomo_event.name
    )
  }
  if (to.fullPath.split('#')[0] !== from.fullPath.split('#')[0]) {
    if (
      from &&
      from.name === 'book' &&
      to &&
      to.name === 'article' &&
      (!to.hash || !to.hash.startsWith('#'))
    ) {
      next(`${to.path}#free-full-text`)
    } else {
      const productionSearchPath = getProductionSearchPath()
      const betaSearchPath = getBetaSearchPath()
      if (isBeta() && to.name === productionSearchPath) {
        router.push(
          `${to.path.replace(productionSearchPath, betaSearchPath)}?query=${
            to.query.query
          }`
        )
      } else next()
    }
  } else if (to.path === '/' && from.path === '/') {
    next()
  }
})

export default router

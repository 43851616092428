const namespaced = true

const getDefaultState = () => {
  return {
    sections: [
      {
        heading: 'h2',
        id: 'abstract',
        title: 'Abstract',
        component: 'abstract',
        collapsible: false,
        preload: true,
        visible: true,
        scroll: true,

        render: true,
        inFrame: true,
      },
      {
        heading: 'h2',
        id: 'figures-and-tables',
        title: 'Figures',
        component: 'figures-and-tables',
        collapsible: false,
        preload: true,
        visible: true,
        scroll: true,

        render: false,
        inFrame: false,
      },
      {
        heading: 'h2',
        id: 'free-full-text',
        title: 'Free full text',
        component: 'free-full-text',
        collapsible: true,
        preload: false,
        visible: false,
        scroll: true,

        render: false,
        inFrame: false,
      },
      {
        heading: 'h2',
        id: 'full-text-links',
        title: 'Full text links',
        component: 'full-text-links',
        collapsible: false,
        preload: true,
        visible: false,
        scroll: true,

        render: false,
        inFrame: false,
      },
      {
        heading: 'h2',
        id: 'references',
        title: 'References',
        component: 'references',
        collapsible: true,
        preload: true,
        visible: false,
        scroll: true,

        render: false,
        inFrame: false,
      },
      {
        heading: 'h2',
        id: 'priority-number',
        title: 'Priority number',
        component: 'priority-number',
        class: 'data-section',
        collapsible: false,
        preload: true,
        visible: false,
        scroll: true,

        render: false,
        inFrame: false,
      },
      {
        heading: 'h2',
        id: 'classifier',
        title: 'Classifier',
        component: 'classifier',
        class: 'data-section',
        collapsible: false,
        preload: true,
        visible: false,
        scroll: true,

        render: false,
        inFrame: false,
      },
      {
        heading: 'h2',
        id: 'impact',
        title: 'Citations & impact',
        component: 'impact',
        class: 'data-section',
        collapsible: true,
        preload: false,
        visible: false,
        scroll: true,
        dataTags: [{ id: 'wikipedia', title: '' }],
        render: false,
        inFrame: false,
      },
      {
        heading: 'h2',
        id: 'data',
        title: 'Data',
        component: 'data-provider-collection',
        class: 'data-section',
        collapsible: true,
        preload: false,
        visible: false,
        scroll: true,
        dataTags: [
          {
            id: 'supporting_data',
            title: 'Data behind the article',
            descriptiveText:
              'This data has been text mined from the article, or deposited into data resources.',
          },
          {
            id: 'related_data',
            title: 'Data that cites the article',
            descriptiveText:
              'This data has been provided by curated databases and other sources that have cited the article.',
          },
        ],
        render: true,
        inFrame: false,
      },
      {
        heading: 'h2',
        id: 'reviews',
        title: 'Reviews & evaluations',
        component: 'reviews-and-evaluations',
        class: 'data-section',
        collapsible: true,
        preload: false,
        visible: false,
        scroll: true,
        render: true,
        inFrame: false,
      },
      {
        heading: 'h2',
        id: 'protocols-materials',
        title: 'Protocols & materials',
        component: 'data-provider-collection',
        class: 'data-section',
        collapsible: true,
        preload: false,
        visible: false,
        scroll: true,
        dataTags: [{ id: 'protocols', title: '' }],
        render: true,
        inFrame: false,
      },
      {
        heading: 'h2',
        id: 'lay-summaries',
        title: 'Lay summaries',
        component: 'data-provider-collection',
        class: 'data-section',
        collapsible: true,
        preload: false,
        visible: false,
        scroll: true,
        dataTags: [
          { id: 'plain_english', title: 'Plain language description' },
          { id: 'press_releases', title: 'Press releases' },
        ],
        render: true,
        inFrame: false,
      },
      {
        heading: 'h2',
        id: 'similar-articles',
        title: 'Similar Articles',
        component: 'similar-articles',
        class: 'data-section',
        collapsible: true,
        preload: true,
        visible: false,
        scroll: true,

        render: true,
        inFrame: false,
      },
      {
        heading: 'h2',
        id: 'funding',
        title: 'Funding',
        component: 'funding',
        class: 'data-section',
        collapsible: true,
        preload: true,
        visible: false,
        scroll: true,
        render: false,
        inFrame: false,
      },
    ],
  }
}

const state = getDefaultState()
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  changeSectionVariable(state, object) {
    const section = state.sections.find(function (section) {
      return section.id === object.id
    })
    if (section) {
      Object.keys(object).forEach((key) => (section[key] = object[key]))
    }
  },
}
const checkAtLeastDataTagExist = (sectionTags, dataLinksTags) => {
  const sectionTagIds = sectionTags.map((tagObj) => tagObj.id)
  return dataLinksTags.find((tag) => sectionTagIds.includes(tag))
}

const actions = {
  async addSectionsData({ rootState, commit, dispatch }) {
    const abstract = rootState.article.abstract.abstract
    const dataLinksTags = abstract.dataLinksTagsList
      ? abstract.dataLinksTagsList.dataLinkstag
      : []
    const hasAltmetrics = dataLinksTags.indexOf('altmetrics') > -1
    const dbRecordsCount = dataLinksTags.indexOf('related_data') > -1
    if (
      abstract.hasEvaluations &&
      abstract.hasEvaluations.toLowerCase() === 'y' &&
      !Object.keys(
        rootState.article.evaluations.evaluationList.evaluationsGroup
      ).length
    ) {
      await dispatch('article/evaluations/loadEvaluations', null, {
        root: true,
      })
    }
    const hasReviews = rootState.article.evaluations.evaluationList.length > 0
    const { fullTextLinks } = rootState.article.fullTextLinks

    const figsList = ['MED', 'PMC', 'PPR']
    const citesList = ['MED', 'PMC', 'PPR', 'CTX', 'CIT', 'CBA', 'AGR']
    commit('changeSectionVariable', {
      id: 'free-full-text',
      render: abstract.hasFulltext,
      preload: abstract.source === 'PPR',
      visible: abstract.source === 'PPR',
      collapsible: !abstract.botRequest,
    })

    commit('changeSectionVariable', {
      id: 'figures-and-tables',
      render:
        abstract.inEPMC === 'Y' &&
        abstract.isOpenAccess === 'Y' &&
        figsList.indexOf(abstract.source) >= 0 &&
        !abstract.botRequest,
    })
    commit('changeSectionVariable', {
      id: 'references',
      render: abstract.hasReferences === 'Y' && !abstract.hasFulltext,
      collapsible: !abstract.botRequest,
    })
    commit('changeSectionVariable', {
      id: 'impact',
      render:
        citesList.indexOf(abstract.source) >= 0 &&
        (checkAtLeastDataTagExist(
          state.sections.find((section) => section.id === 'impact').dataTags,
          dataLinksTags
        ) ||
          hasAltmetrics ||
          abstract.scite ||
          abstract.citedByCount ||
          dbRecordsCount ||
          hasReviews),
      collapsible: !abstract.botRequest,
      hasAltmetrics: true,
    })
    commit('changeSectionVariable', {
      id: 'funding',
      render: abstract.grantsList,
      collapsible: !abstract.botRequest,
    })
    commit('changeSectionVariable', {
      id: 'priority-number',
      render: abstract.patentDetails
        ? !!abstract.patentDetails.priorityList
        : false,
      collapsible: !abstract.botRequest,
    })
    commit('changeSectionVariable', {
      id: 'classifier',
      render: abstract.patentDetails
        ? !!abstract.patentDetails.classifierList
        : false,
      collapsible: !abstract.botRequest,
    })
    commit('changeSectionVariable', {
      id: 'full-text-links',
      render:
        !abstract.hasFulltext &&
        (fullTextLinks.length ||
          abstract.doi ||
          dataLinksTags.find((tag) => tag === 'full_text')),
      collapsible: !abstract.hasFulltext && !abstract.botRequest,
      heading: abstract.hasFulltext ? 'h3' : 'h2',
    })
    commit('changeSectionVariable', {
      id: 'similar-articles',
      render: abstract.source === 'MED',
      collapsible: !abstract.botRequest,
    })
    commit('changeSectionVariable', {
      id: 'data',
      render:
        abstract.hasDataLinks &&
        checkAtLeastDataTagExist(
          state.sections.find((section) => section.id === 'data').dataTags,
          dataLinksTags
        ),
      collapsible: !abstract.botRequest,
    })
    commit('changeSectionVariable', {
      id: 'lay-summaries',
      render:
        abstract.hasDataLinks &&
        checkAtLeastDataTagExist(
          state.sections.find((section) => section.id === 'lay-summaries')
            .dataTags,
          dataLinksTags
        ),
      collapsible: !abstract.botRequest,
    })
    commit('changeSectionVariable', {
      id: 'protocols-materials',
      render:
        abstract.hasDataLinks &&
        checkAtLeastDataTagExist(
          state.sections.find((section) => section.id === 'protocols-materials')
            .dataTags,
          dataLinksTags
        ),
      collapsible: !abstract.botRequest,
    })
    commit('changeSectionVariable', {
      id: 'reviews',
      render: hasReviews,
      collapsible: !abstract.botRequest,
    })
  },
}

export default {
  namespaced,
  state,
  mutations,
  actions,
}
